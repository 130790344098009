'use strict';
import WebConfig from './config';

const isDev = false;
var API_URL = WebConfig.production.api;
if (window.location.hostname.indexOf('local')!==-1 || isDev) {
  API_URL = WebConfig.development.api;
}

function convertMonthStringToNumber(m) {
  var arr = {
    'Jan': 1,
    'Feb': 2,
    'Mar': 3,
    'Apr': 4,
    'May': 5,
    'Jun': 6,
    'Jul': 7,
    'Aug': 8,
    'Sep': 9,
    'Oct': 10,
    'Nov': 11,
    'Dec': 12
  };
  return arr[m];
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN(c = Math.abs(c))
      ? 2
      : c,
    d = d == undefined
      ? "."
      : d,
    t = t == undefined
      ? ","
      : t,
    s = n < 0
      ? "-"
      : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3
      ? j % 3
      : 0;
  return s + (j
    ? i.substr(0, j) + t
    : "") + i
      .substr(j)
      .replace(/(\d{3})(?=\d)/g, "$1" + t) + (c
        ? d + Math.abs(n - i).toFixed(c).slice(2)
        : "");
};


class Utils {
  constructor() { }

  static formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  static formatFullDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      h = d.getHours(),
      m = d.getMinutes(),
      s = d.getSeconds();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if (h < 10) h = '0' + h;
    if (m < 10) m = '0' + m;
    if (s < 10) s = '0' + s;

    return [year, month, day].join('-') + ' ' + [h,m,s].join(':');
  }

  static isSameDate(date1, date2) {
    var y = date1.getFullYear() == date2.getFullYear(),
      m = date1.getMonth() == date2.getMonth(),
      d = date1.getDate() == date2.getDate();
    return y && m && d;
  }

  static getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  static convertJsonToColorString(obj) {
    var color = 'rgba(' + obj.rgb.r + ',' + obj.rgb.g + ',' + obj.rgb.b + ',' + obj.rgb.a + ')';
    return color;
  }

  static encodePassword(pass, callback) {
    var encrypted = this.utoa(pass);
    callback(encrypted);
  }

  static utoa(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }
  // base64 encoded ascii to ucs-2 string
  static atou(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  static serverAPI() {
    return API_URL;
  }

  static getFileExtension(file) {
    var arr = file.split('.'),
      ext = arr[arr.length - 1];
    return ext;
  }

  static arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }
    return a;
  }

  static getFriendlyURL(o_url) {
    if (typeof o_url !== 'string') return '';
    o_url = o_url.trim();
    o_url = o_url.replace(/(á|à|ả|ã|ạ|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ|A|Á|À|Ả|Ã|Ạ|Ă|Ắ|Ằ|Ẳ|Ẵ|Ặ|Â|Ấ|Ầ|Ẩ|Ẫ|Ậ)/g, 'a');
    o_url = o_url.replace(/đ|Đ/g, 'd');
    o_url = o_url.replace(/(é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ|É|È|Ẻ|Ẽ|Ẹ|Ê|Ế|Ề|Ể|Ễ|Ệ)/g, 'e');
    o_url = o_url.replace(/(í|ì|ỉ|ĩ|ị|Í|Ì|Ỉ|Ĩ|Ị)/g, 'i');
    o_url = o_url.replace(/(ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ|Ó|Ò|Ỏ|Õ|Ọ|Ô|Ố|Ồ|Ổ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ở|Ỡ|Ợ)/g, 'o');
    o_url = o_url.replace(/(ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự|Ú|Ù|Ủ|Ũ|Ụ|Ư|Ứ|Ừ|Ử|Ữ|Ự)/g, 'u');
    o_url = o_url.replace(/(ý|ỳ|ỷ|ỹ|ỵ|Ý|Ỳ|Ỷ|Ỹ|Ỵ)/g, 'y');
    o_url = this.replaceAll(o_url, ' ', '-');
    return o_url.toLowerCase();
  }

  static removeUnicode(text) {
    if (typeof text !== 'string') return '';
    text = text.trim();
    text = text.replace(/(á|à|ả|ã|ạ|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ|A|Á|À|Ả|Ã|Ạ|Ă|Ắ|Ằ|Ẳ|Ẵ|Ặ|Â|Ấ|Ầ|Ẩ|Ẫ|Ậ)/g, 'a');
    text = text.replace(/đ|Đ/g, 'd');
    text = text.replace(/(é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ|É|È|Ẻ|Ẽ|Ẹ|Ê|Ế|Ề|Ể|Ễ|Ệ)/g, 'e');
    text = text.replace(/(í|ì|ỉ|ĩ|ị|Í|Ì|Ỉ|Ĩ|Ị)/g, 'i');
    text = text.replace(/(ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ|Ó|Ò|Ỏ|Õ|Ọ|Ô|Ố|Ồ|Ổ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ở|Ỡ|Ợ)/g, 'o');
    text = text.replace(/(ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự|Ú|Ù|Ủ|Ũ|Ụ|Ư|Ứ|Ừ|Ử|Ữ|Ự)/g, 'u');
    text = text.replace(/(ý|ỳ|ỷ|ỹ|ỵ|Ý|Ỳ|Ỷ|Ỹ|Ỵ)/g, 'y');
    return text;
  }

  static getBase64(file, callback) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (typeof callback === 'function') {
        callback(reader.result);
      } else {
        return reader.result;
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  static replaceAll(str, e, t) {
    var r = str;
    return r.replace(new RegExp(e.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "g"), t)
  }

  static fixImagePath(image) {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'production') {
      var i = image;
      if (image.indexOf(WebConfig.development.image) !== -1) {
        i = image.replace(WebConfig.development.image, WebConfig.production.image);
      } else {
        if (image.indexOf('//') == -1) {
          i = image = WebConfig.production.api + image;
        }
      }
      //console.log(i);
      return i;
    } else {
      var i = image;
      if (image.indexOf('//') == -1) {
        i = image = WebConfig.development.api + image;
      }
      //console.log(i);
      return i;
    }
    return image;
  }

  static getFullDate(date) {
    let y = date.getFullYear(),
      m = (date.getMonth() + 1),
      d = date.getDate();
    if (d < 10)
      d = '0' + d;
    if (m < 10)
      m = '0' + m;
    return y + '-' + m + '-' + d;
  }

  static objectToArray(obj) {
    return Object.keys(obj).map(key => obj[key]);
  }

  static fullProductName(product) {
    let f = product.name,
      p = product.properties;
    var name = f + ' [';
    for (let i = 0, len = p.length; i < len; i++) {
      let n = this.objectToArray(p[i]);
      n.pop();
      //console.log(n);
      name += n.join(':') + ', ';
    }
    name = name.slice(0, -2);
    name += ']';
    return name;
  }

  static formatCurrency(number) {
    return number.formatMoney(0, ',', '.') + ' VNĐ';
  }

  static validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static login(username, password, callback) {
    let url = API_URL + '/api/login';
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function (e) {
      let res = JSON.parse(xmlhttp.responseText);
      //console.log(res);
      if (typeof callback === 'function')
        callback(res);
    };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send(JSON.stringify({ username: username, password: password }));

  }

  static logout(username, callback) {
    let url = API_URL + '/api/logout';

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function (e) {
      let res = JSON.parse(xmlhttp.responseText);
      //console.log(res);
      if (typeof callback === 'function')
        callback(res);
    };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('user_token'));
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send(JSON.stringify({ username }));

  }

  static createFolder(folder, callback) {
    var obj = { folder: folder };
    let url = API_URL + '/api/folder/create/';
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function (e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
    };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('user_token'));
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static sendMail(form, callback) {
    var obj = {};
    obj.email = form.email;
    obj.emailsubject = form.emailsubject;
    obj.name = form.fullname;
    obj.content = form.emailbody;
    obj.sendto = form.sendto;

    let url = API_URL + '/api/sendemail/';
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function (e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback == 'function') callback(res);
    }
    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('user_token'));
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static upload(file, type, ext, successCallback, errorCallback) {
    var obj = { file: file, type: type, ext: ext };
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', API_URL + '/api/upload');
    xmlhttp.addEventListener('load', () => {
      const response = JSON.parse(xmlhttp.responseText);
      successCallback({ data: { link: response.photoURL } })
    });
    xmlhttp.addEventListener('error', () => {
      const error = JSON.parse(xmlhttp.responseText);
      errorCallback(error);
    });
    xmlhttp.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('user_token'));
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static delete(file, callback) {
    var obj = { file: file };

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function (e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
    };

    xmlhttp.open("POST", API_URL + '/api/mediadelete');
    xmlhttp.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('user_token'));
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static getThumbnail(file, size) {
    let url = API_URL + '/api/getimage/' + encodeURIComponent(file) + '?size=' + size.width + 'x' + size.height;
    return url;
  }

  static getFiles(folder, callback) {
    let url = API_URL + '/api/media/' + encodeURIComponent(folder);
    if (folder === '' || folder === '/') url = API_URL + '/api/mediaroot/';
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function () {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function')
          callback(res);
      } catch (error) {
        if (typeof callback === 'function') callback({
          status: 'false',
          results: [],
          message: error
        });
      }
    };
    // let obj = { folder: folder }
    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static getAllMedia(callback) {
    let url = API_URL + '/api/mediaall/';
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function () {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function')
          callback(res);
      } catch (error) {
        if (typeof callback === 'function') callback({
          status: 'false',
          results: [],
          message: error
        });
      }
    };
    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static getListData(api, page, pagesize, last_id, filter, forward, callback) {
    if (filter === '') filter = 'null';
    let url = API_URL + '/api/listing/' + api + '/' + page + '/' + pagesize + '/' + last_id + '/' + filter + '/null/' + forward;

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function () {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function') callback(res);
      } catch (error) {
        if (typeof callback === 'function') callback({
          status: 'false',
          results: [],
          message: error
        });
      }

    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static getAllItems(api, callback) {
    let url = API_URL + api;
    //console.log(url);
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function (e) {
      //console.log(xmlhttp.responseText);
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static getListDataSort(api, direction, sort, page, pagesize, last_id, forward, callback) {
    let url = API_URL + api + direction + '/' + sort + '/' + page + '/' + pagesize + '/' + last_id + '/' + forward;
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function () {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function') callback(res);
    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static getListDataSortWithFilter(api, page, pagesize, last_id, filter,sort, forward,isRev,callback) {
    if (filter === '') filter = 'null';
    let l = 'listing';
    if(isRev) l = 'listing_rev'
    let url = API_URL + '/api/' + l + '/' + api + '/' + page + '/' + pagesize + '/' + last_id + '/' + filter + '/' + sort + '/' + forward;
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function () {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function') callback(res);
      } catch (error) {
        if (typeof callback === 'function') callback({
          status: 'false',
          results: [],
          message: error
        });
      }

    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static getSingleData(table, id, callback) {
    let url = API_URL + '/api/detail/_mrp_' + table + '/' + id;
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function () {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        //console.log(res);
        if (typeof callback === 'function') callback(res);
      } catch (error) {
        callback({ status: 'failed', message: xmlhttp.responseText });
      }
    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static getData(api,parameter, callback) {
    let url = API_URL + '/api/' + api + '/' + parameter;
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function (e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function') callback(res);
      } catch (error) {
        callback({ status: 'failed', message: xmlhttp.responseText });
      }
    };
    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static checkExist(table,field,value,callback) {
    let url = API_URL + '/api/exists/' + table + '/' + encodeURIComponent(field) + '/' + value;
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function (e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        //console.log(res);
        if (typeof callback === 'function') callback(res);
      } catch (error) {
        callback({ status: 'failed', message: xmlhttp.responseText });
      }
    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static _add(api, obj, callback) {
    let url = API_URL + '/api/' + api + '/add';

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    let tk = localStorage.getItem('user_token');
    xmlhttp.onload = function (e) {
      try {
        //console.log(xmlhttp.responseText);
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function')callback(res);
      } catch (error) {
        console.log(error);
      }
    };

    xmlhttp.open("POST", url);
    //xmlhttp.withCredentials = true;
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    tk && xmlhttp.setRequestHeader('Authorization', 'Bearer ' + tk);
    xmlhttp.send(JSON.stringify(obj));
  }

  static _update(api, obj, callback) {
    let url = API_URL + '/api/' + api + '/update';

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    let tk = localStorage.getItem('user_token');
    xmlhttp.onload = function (e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function')callback(res);
      } catch (error) {
        console.log(xmlhttp.responseText);
        console.log(error);
      }
    };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    tk && xmlhttp.setRequestHeader('Authorization', 'Bearer ' + tk);
    xmlhttp.send(JSON.stringify(obj));
  }

  static _updateMany(table,arr,callback) {
    let url = API_URL + '/api/update/'+table;
    let obj = {records: arr};

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    let tk = localStorage.getItem('user_token');
    xmlhttp.onload = function (e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function')callback(res);
      } catch (error) {
        console.log(xmlhttp.responseText);
        console.log(error);
      }
    };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    tk && xmlhttp.setRequestHeader('Authorization', 'Bearer ' + tk);
    xmlhttp.send(JSON.stringify(obj));
  }

  static _delete(api, obj, callback) {
    //var i = window.confirm('Please confirm you want to delete this item, it can\'t rollback!!!');
    var i = window.confirm('Bạn có chắc chắn muốn xóa dữ liệu này? Dữ liệu đã xóa không thể lấy lại !!!');
    let tk = localStorage.getItem('user_token');
    if (i) {
      let url = API_URL + '/api/' + api + '/delete';

      let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
      xmlhttp.onload = function (e) {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function')
          callback(res);
      };

      xmlhttp.open("POST", url);
      xmlhttp.setRequestHeader('Content-Type', 'application/json');
      tk && xmlhttp.setRequestHeader('Authorization', 'Bearer ' + tk);
      xmlhttp.send(JSON.stringify(obj));
    } else {
      return false;
    }
  }

  static _post(api, obj, callback) {
    let url = API_URL + '/api/' + api;

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    let tk = localStorage.getItem('user_token');
    xmlhttp.onload = function (e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function')callback(res);
      } catch (error) {
        console.log(xmlhttp.responseText);
        console.log(error);
      }
    };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    tk && xmlhttp.setRequestHeader('Authorization', 'Bearer ' + tk);
    xmlhttp.send(JSON.stringify(obj));
  }

  static getProperyValue(str,arr,obj) {
    var $this = this;
    if(obj===undefined) return str;
    if(obj[arr[0]]!==undefined&&arr.length) {
      let a = arr.shift();
      if(arr.length) {
        $this.getProperyValue(arr[0],arr,obj[a]);
      } else {
        return obj[a];
      }
    } else {
      return obj;
    }
  }

  static processTemplate(html, dataset) {
    try {
      var $this = this;
      for (var data in dataset) {
        html = html.split("{%" + data + "%}").join(dataset[data]);
      }

      var otherTags = html.match(/{%(.*?)%}/g)||[];
      if(otherTags.length){
        otherTags.map(x=>{
          let s = x.replace('{%','').replace('%}','').split('.'),
              sx;
          let a = s.shift();
          sx = $this.getProperyValue(s[0],s,dataset[a]);
          html = html.split(x).join(sx);
        });
      }
      //console.log(html);
      return html;
    } catch (error) {
      //console.log(error);
      return html;
    }
  }

  static getBlockContents(cb){
    var blocks = [],options = {};
    this.getListData('blockcontents',1,100,'0,0','',1,function(data) {
      blocks = data.results;
      //console.log(blocks);
      blocks.map(x=>{
        options[x.url] =x.name;
      });

      //console.log(JSON.stringify(options));
      cb(options);
    });
  }

  static setupEditor($, folder, options) {

    $.FroalaEditor.DefineIcon('cmsStyle', { NAME: 'cog' });
    $.FroalaEditor.DefineIcon('cmsTemplate', { NAME: 'server' });
    $.FroalaEditor.DefineIcon('cmsFunctions', { NAME: 'barcode' });
    $.FroalaEditor.RegisterCommand('cmsStyle', {
      title: 'ReactPress styles',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: {
        'gap': 'Insert a gap',
        'gap half': 'Insert a half gap',
        'container': 'Warp content by a container',
        'section': 'Insert a section',
        'section-heading': 'Apply style heading',
        'section-break': 'Insert section break line',
        'button-primary': 'Insert a primary style button',
        'button-secondary': 'Insert a secondary style button',
        'button-white': 'Insert a white style button',
        'button-border-white': 'Insert a border style button',
        'list-plus': 'Insert a list with plus icon prefix',
        'list-plus-square': 'Insert a list with plus-square icon prefix',
        'list-minus': 'Insert a list with minus icon prefix',
        'list-minus-square': 'Insert a list with minus-square icon prefix',
        'list-star': 'Insert a list with star icon prefix',
        'list-dots': 'Insert a list with dot icon prefix',
        'list-check': 'Insert a list with check icon prefix',
        'list-check-square': 'Insert a list with check-square icon prefix',
        'list-check-square-o': 'Insert a list with check-square-o icon prefix',
        'list-check-circle': 'Insert a list with check-circle icon prefix',
        'list-check-circle-o': 'Insert a list with check-circle-o icon prefix',
      },
      callback: function (cmd, val) {
        var editor = this;
        var html;

        var insetList = function(style) {
          let n = window.prompt('Nhập số items',3);
          if(!parseInt(n)) {
            alert('Chỉ nhập số!!!');
            return false;
          }
          html = '<ul class="editor-list">';
          for(let i = 1; i<=n; i++){
            html+='<li><i class="fa fa-'+style+'"></i> Lorem ipsum dolor sit amet, vero melius vulputate ei sit, no dolorem similique nam</li>';
          }
          html+='</ul>';
          editor.html.insert(html, true);
        }

        switch (val) {
          case 'container':
            html = editor.html.getSelected();
            editor.html.insert('<div class="container">' + html + '</div>', true);
            //editor.html.wrap(false,false,false);
            //editor.paragraphStyle.apply('container');
            break;
          case 'section':
            html = editor.html.getSelected();
            editor.html.insert('<section>' + html + '</section>', true);
            //editor.html.wrap(false,false,false);
            //editor.paragraphStyle.apply('container');
            break;
          case 'gap':
            editor.html.insert('<div class="gap"></div>', true);
            break;
          case 'gap-half':
            editor.html.insert('<div class="gap half"></div>', true);
            break;
          case 'section-heading':
            html = editor.html.getSelected();
            editor.html.insert('<h2 class="heading">' + html + '</h2>', true);
            break;
          case 'section-break':
            editor.html.insert('<div class="line-break"></div>', false);
            break;
          case 'button-primary':
            editor.html.insert('<a class="btn btn-primary" href="#" title="Edit text and Link">Edit text and Link</a>', true);
            break;
          case 'button-secondary':
            editor.html.insert('<a class="btn btn-secondary" href="#" title="Edit text and Link">Edit text and Link</a>', true);
            break;
          case 'button-white':
            editor.html.insert('<a class="btn btn-white" href="#" title="Edit text and Link">Edit text and Link</a>', true);
            break;
          case 'button-border-white':
            editor.html.insert('<a class="btn btn-border-grey" href="#" title="Edit text and Link">Edit text and Link</a>', true);
            break;
          case 'list-plus':
            insetList('plus');
            break;
          case 'list-plus-square':
            insetList('plus-square');
            break;
          case 'list-minus':
            insetList('minus');
            break;
          case 'list-minus-square':
          insetList('minus-square');
            break;
          case 'list-star':
            insetList('star');
            break;
          case 'list-dots':
            insetList('dot');
            break;
          case 'list-check':
            insetList('check');
            break;
          case 'list-check-square':
            insetList('check-square');
            break;
          case 'list-check-square-o':
            insetList('check-square-o');
            break;
          case 'list-check-circle':
            insetList('check-circle');
            break;
          case 'list-check-circle-o':
            insetList('check-circle-o');
            break;
          default:
            return true;
        }
      },
      // Callback on refresh.
      refresh: function ($btn) {
        //console.log('do refresh');
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        //console.log('do refresh when show');
      }
    });

    $.FroalaEditor.RegisterCommand('cmsTemplate', {
      title: 'ReactPress template',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: {
        'section': 'Insert section template',
        'banner': 'Insert static banner',
        'lecture': 'Insert lecture template',
        'h-tabs': 'Insert top-down tab',
        'v-tabs': 'Insert left-right tab',
        'accordion': 'Insert accordion',
      },
      callback: function (cmd, val) {
        var editor = this;
        switch (val) {
          case 'banner':
            var str = '<div class="banner-static solid-bg blue"><h2 class="banner-title">Page Title</h2><div class="banner-links"><a href="/vn/" title="Title 1">Title 1</a><span class="fa fa-angle-right"></span><a href="/vn/" title="Title 2">Title 2</a></div></div>';
            editor.html.insert(str, true);
            break;
          case 'section':
            var str = '<section><div class="container"><div class="gap">&nbsp;</div><h2 class="heading">Section heading ...</h2><div class="desc"><p>Lorem ipsum dolor sit amet, omnis blandit persequeris an quo, exerci nostrud euripidis ea vel. His an aliquid facilisi, mea te eripuit necessitatibus, ne cum epicuri necessitatibus.</p></div><div class="content"><div class="text">Lorem ipsum dolor sit amet, omnis blandit persequeris an quo, exerci nostrud euripidis ea vel. His an aliquid facilisi, mea te eripuit necessitatibus, ne cum epicuri necessitatibus.</div></div><div class="gap">&nbsp;</div></div></section>';
            editor.html.insert(str, true);
            break;
          case 'lecture':
            var str = '<p class="lecture-summary"><i class="fa fa-star"></i> Lorem ipsum dolor sit amet, vero melius vulputate ei sit, no dolorem similique nam</p>';
            editor.html.insert(str, true);
            break;
          case 'h-tabs':
            var str = '<div class="horizontal-tabs-wrapper">';
            str += '<ul class="nav nav-horizontal nav-accordion">';
            str += '<li class="nav-item"><a class="nav-link active" data-toggle="tab-accordion" href="#item1">Item title 1</a></li>';
            str += '<li class="nav-item"><a class="nav-link" data-toggle="tab-accordion" href="#item2">Item title 2</a></li>';
            str += '<li class="nav-item"><a class="nav-link" data-toggle="tab-accordion" href="#item3">Item title 3</a></li>';
            str += '</ul><div class="tab-content"><div class="tab-pane active" id="item1">Content of item 1</div><div class="tab-pane" id="item2">Content of item 2</div><div class="tab-pane" id="item3">Content of item 3</div></div></div>';
            editor.html.insert(str, true);
            break;
          case 'v-tabs':
            var str = '<div class="vertical-tabs-wrapper">';
            str += '<ul class="nav nav-vertical nav-accordion">';
            str += '<li class="nav-item"><a class="nav-link active" data-toggle="tab-accordion" href="#item1">Item title 1</a></li>';
            str += '<li class="nav-item"><a class="nav-link" data-toggle="tab-accordion" href="#item2">Item title 2</a></li>';
            str += '<li class="nav-item"><a class="nav-link" data-toggle="tab-accordion" href="#item3">Item title 3</a></li>';
            str += '</ul><div class="tab-content"><div class="tab-pane active" id="item1">Content of item 1</div><div class="tab-pane" id="item2">Content of item 2</div><div class="tab-pane" id="item3">Content of item 3</div></div></div>';
            editor.html.insert(str, true);
            break;
          case 'accordion':
            let n = window.prompt('Nhập số items',3);
            if(!parseInt(n)) {
              alert('Chỉ nhập số!!!');
              return false;
            }
            var str = '<div class="react-accordion-wrapper">';
            for(let i=0;i<n;i++){
              str += '<div class="accordion-item"><button type="button" class="btn-accordion btn btn-default">Accordion '+i+'<i class="ion ion-ios-arrow-down"></i></button><div class="collapse"><div class="accordion-text"><p>Content of item '+i+'</p></div></div></div>'
            }
            str += '</div>';
            editor.html.insert(str, true);
            break;
          default:
            return true;
        }
      },
      // Callback on refresh.
      refresh: function ($btn) {
        //console.log('do refresh');
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        //console.log('do refresh when show');
      }
    });

    $.FroalaEditor.RegisterCommand('cmsFunctions', {
      title: 'ReactPress block content',
      type: 'dropdown',
      focus: false,
      undo: false,
      options: options,
      refreshAfterCallback: true,
      callback: function (cmd, val) {
        let editor = this;
        let str = '{@BlockContent:'+val+'}';
        editor.html.insert(str, true);
      },
      // Callback on refresh.
      refresh: function ($btn) {
        //console.log('do refresh');
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        //console.log('do refresh when show');
      }
    });

    var editorConfig = {
      placeholderText: '...',
      imageUploadURL: Utils.serverAPI() + '/api/upload/' + folder,
      imageUploadMethod: 'POST',
      imageMaxSize: 5 * 1024 * 1024,
      imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
      imageManagerLoadURL: Utils.serverAPI() + '/api/mediaall',
      height: 500,
      htmlRemoveTags: ['script', 'base'],
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste','fontAwesome','specialCharacters','ionicons'],
      toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertFile', 'insertTable', '|', 'emoticons', 'specialCharacters', 'fontAwesome', 'ionicons','insertHR', 'selectAll', 'clearFormatting', '|', 'spellChecker', 'help', 'cmsStyle', 'cmsTemplate', 'cmsFunctions', 'html', '|', 'undo', 'redo'],
      codeMirrorOptions: {
        theme: 'rubyblue',
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 4,
      },
      language: 'vi',
      events: {
        'froalaEditor.image.inserted': function (e, editor, $img, response) {
          // Image was inserted in the editor.
          //console.log($img.attr('src'));'
          if(!$img.attr('alt')) {
            let t = $img.attr('src').split('/');
            $img.attr('alt',t[t.length-1]);
          }
        },
        'froalaEditor.image.replaced': function (e, editor, $img, response) {
          // Image was replaced in the editor.
          //console.log($img.attr('src'));'
          if(!$img.attr('alt')) {
            let t = $img.attr('src').split('/');
            $img.attr('alt',t[t.length-1]);
          }
        }
      }
    }

    return editorConfig;


  }

  static setupBasicEditor() {
    const editorConfigBasic = {
      placeholderText: '...',
      toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'undo', 'redo', '|', 'html'],
      height: 200,
      codeMirrorOptions: {
        theme: 'rubyblue',
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 4
      },
      language: 'vi'
    }
    return editorConfigBasic;
  }

  static getBeautyDate(date, locale) {
    var str = (new Date(date)).toDateString();
    var arr = str.split(' ');
    if (locale === undefined) locale = 'vn';
    switch (locale.toLowerCase()) {
      case 'vn':
        return `${arr[2]} tháng ${convertMonthStringToNumber(arr[1])} năm ${arr[3]}`;
      case 'kr':
        return arr[3] + ' 년 ' + convertMonthStringToNumber(arr[1]) + ' 월 ' + arr[2] + ' 일';
      default:
        return arr[1] + ' ' + arr[2] + ' ' + arr[3];
    }
  }

  static variantExist(a, b) {
    var isDuplicate = true;
    var a_v = a.values,
      b_v = b.values;
    for (var property in a_v) {
      if (property !== 'price' && property !== 'status') {
        if (a_v[property] !== b_v[property]) {
          isDuplicate = false;
          break;
        }
      }
    }
    return isDuplicate;
  }

  static generatePassword() {
    var pass = '';
    var res = ['ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz','0123456789','~!@#$%^&*()_+=-;:,.<>/?`'];

    for(let i=0,len=8;i<len;i++) {
      let t = getRandomInt(0,2);
      pass+=res[t].charAt(getRandomInt(0,res[t].length));
    }

    return pass;
  }

}

export default Utils;
