const WebConfig = {
  development: {
    api: 'http://reactpress.local:5102',
    image: 'http://reactpress.local:5102/data/upload/images/',
    // api: 'http://api.bdstraining.vn',
    // image: 'http://api.bdstraining.vn/data/upload/images/',
    itemPerPage: 50,
  },
  staging: {
    api: 'https://api.bdstraining.vn',
    image: 'https://api.bdstraining.vn/data/images/',
    itemPerPage: 50,
  },
  production: {
    api: 'https://api.bdstraining.vn',
    image: 'https://api.bdstraining.vn/data/upload/images/',
    itemPerPage: 50,
  }
};

export default WebConfig;